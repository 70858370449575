@import './mixins';
@import './fonts';
// @import './reset'; //?? bootstrap reboots replace this
@import './colors';
@import './vars';
@import './helpers';
@import './components/main.scss';

// Evita grid overflow breaks with paddings
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  padding   : 0;
  margin    : 0 !important;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

.card {
  border: 0;
}
