.activity-wrapper {
  // margin: 15px 20px;

  .activity-statement {
    @extend .font-20;
    @extend .font-primary;
    @extend .text-blue;
  }

  .separator {
    // margin: 15px 0 25px 0;
  }
}

// List Options

.activity__options > div.activity__option:nth-child(n+2) {
  margin-top: 30px;
}
