// GROWTHROAD
$white            : #fff;
$neutral          : #FCFCFC;
$grey-dark        :#4D4D4D;
$grey             :#707070;
$grey-alt         :#94969B;
$grey-light       :#E4E6EB;
$grey-extra-light :#f9f9f9;
$blue-dark        :#292E37;
$blue             : #374052;
$blue-light       : #078CC7;
$blue-extra-light : #90AAED;
$green            : #00CAA8;
$orange           : #FF9E68;
$orange-alt       : #F5C17D;
$red              : #DE377C;
$red-alt          : #FF3131;
$purple           : #9E3678;
$purple-light     : #C029C0;

$gradient-one    : transparent linear-gradient(136deg, $green 0%, $blue-light 100%) 0% 0% no-repeat padding-box;
$gradient-one    : transparent linear-gradient(136deg, $orange-alt 0%, $red-alt 100%) 0% 0% no-repeat padding-box;
$gradient-one    : transparent linear-gradient(136deg, $blue-extra-light 0%, $purple 100%) 0% 0% no-repeat padding-box;
// GROWTHROAD
// $test         : rgba(55, 64, 82, .5);
$bg_main         : $neutral;
$bg_blocks       : $white;
